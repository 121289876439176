import Button from 'components/FlowUi/Button';
import Card from 'components/FlowUi/Card';
import { Input } from 'components/Mui';
import { useContext } from 'react';
import { LoginContext } from '../../context';
import { loginTypes } from '../../context/LoginContext';
import Link from 'next/link';
import CheckBox from 'components/FlowUi/CheckBox';
import style from './LoginForm.module.css';
import commonStyles from '../../Login.module.css';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  switchView: () => void;
};

const LoginForm = ({ switchView }: Props) => {
  const { state, handleChange, loading, error, handleLoginSubmit } =
    useContext<loginTypes>(LoginContext);

  return (
    <>
      <style jsx>{`
        .login_card_wrapper {
          background: white;
          padding: 30px;
          margin: auto;
          width: 450px;
          border-radius: 4px;
          // box-shadow: 1px 1px 20px 2px #afafaf;
        }
        .login_card_wrapper p {
          margin: 6px;
          text-transform: uppercase;
        }
        .error {
          color: red;
          font-family: 'Figtree';
          font-size: 14px;
          font-style: normal;
          line-height: 18px;
        }
        .error-wrapper {
          margin: 0 0 12px;
        }
        .wrapper {
          width: 100%;
          margin: 24px 0 12px;
        }
        @media (max-width: 920px) {
          .login_card_wrapper {
            box-shadow: none;
            margin: 0 auto auto auto;
            padding: 0 30px 30px 30px;
            width: 95vw;
          }
        }
      `}</style>
      <div className="wrapper">
        <h3 className={style.Title}>Welcome back!</h3>
        <p className={style.Subtitle}>Please login to your account.</p>

        {/* <Card removeCard={true}> */}
        <form onSubmit={handleLoginSubmit} className={style.Form}>
          <div className={style.Inputs}>
            <Input
              className={style.Input}
              autoFocus={true}
              label="Username"
              value={state.username}
              handleChange={handleChange}
              name="username"
              withMargin={false}
              warning={!!error}
              warningMessageIcon
            />
            <div className={commonStyles.InputWithErrorMsg}>
              <Input
                className={style.Input}
                label="Password"
                value={state.password}
                handleChange={handleChange}
                name="password"
                type="password"
                withMargin={false}
                warning={!!error}
              />
              {error && (
                <div className={commonStyles.errorMsg}>
                  <IoIosWarning />
                  <span>{error}</span>
                </div>
              )}
            </div>
            {/* 
              TODO: Temporary hidden
              <CheckBox
                className={style.Checkbox}
                label='Remember me'
                handleChange={() => {}}
              /> */}
          </div>
          <div className={style.Actions}>
            <div />
            <Button
              variant="tertiary"
              handleClick={switchView}
              buttonType="button"
              className={style.RemindPasswordSwitcherBtn}
            >
              Forgot password?
            </Button>
          </div>
          <Button
            height={'44px'}
            className={style.Btn}
            loading={loading}
            buttonType="submit"
            fullWidth
          >
            Login
          </Button>
        </form>
        {/* </Card> */}
      </div>
    </>
  );
};

export default LoginForm;
