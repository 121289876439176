import Button from 'components/FlowUi/Button';
import { Input } from 'components/Mui';
import style from './RemindPassword.module.css';
import axios from 'axios';
import React, { useState, useCallback } from 'react';
import commonStyles from '../../Login.module.css';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  switchView: () => void;
};

export const RemindPassword = ({ switchView }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setEmail(target.value);
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setPending(true);
    setSuccessMessage('');
    try {
      e.preventDefault();
      const response = await axios
        .post('/api/user/lost-password', {
          mail: email,
        })
        .catch(({ response }) => {
          setError(response?.data?.message);
        });
      if (response && response.status === 200) {
        setSuccessMessage(
          response.data.data.message || 'Password reset email has been sent.',
        );
      } else {
        throw new Error('There was an error');
      }
    } catch (e) {
      setError('There was an error');
    }
    setPending(false);
  };

  return (
    <div className="wrapper">
      <h3 className={style.Title}>Reset password</h3>
      <p className={style.Subtitle}>
        {successMessage
          ? successMessage
          : 'Please enter the email address associated with your account.'}
      </p>

      {!successMessage && (
        <form onSubmit={handleSubmit} className={style.Form}>
          <div className={style.Inputs}>
            <div className={commonStyles.InputWithErrorMsg}>
              <Input
                className={style.Input}
                autoFocus={true}
                value={email}
                handleChange={handleChange}
                name="email"
                placeHolder="Insert email"
                withMargin={false}
                warning={!!error}
              />
              {error && (
                <div className={commonStyles.errorMsg}>
                  <IoIosWarning />
                  <span>{error}</span>
                </div>
              )}
            </div>
          </div>
          <Button
            height={'44px'}
            className={style.Btn}
            loading={pending}
            buttonType="submit"
            fullWidth
          >
            Reset password
          </Button>
        </form>
      )}

      <div className={style.Actions}>
        <Button
          className={style.RemindPasswordSwitcherBtn}
          variant="tertiary"
          handleClick={switchView}
        >
          Back to Log in page
        </Button>
      </div>
    </div>
  );
};
