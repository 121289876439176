import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import Card from 'components/FlowUi/Card';
import { useCallback, useContext, useState } from 'react';
import Branding from '../components/Branding';
import LoginForm from '../components/LoginForm';
import MfaCheck from '../components/MfaCheck/MfaCheck';
import MfaSetUp from '../components/MfaSetUp';
import { LoginContext } from '../context';
import { loginTypes } from '../context/LoginContext';
import styles from './LoginBodyForm.module.css';
import { RemindPassword } from '../components/RemindPassword/RemindPassword';

const LoginBody = () => {
  const { user, loginSuccess } = useContext<loginTypes>(LoginContext);
  const [displayRemindPassword, setDisplayRemindPassword] =
    useState<boolean>(false);
  const switchView = useCallback(() => setDisplayRemindPassword((s) => !s), []);

  return (
    <div className={loginSuccess ? styles.success_wrapper : styles.wrapper}>
      {!loginSuccess && (
        <div className={styles.login_wrapper}>
          {user && user.mfa && <MfaCheck />}
          {user && !user.mfa && <MfaSetUp />}
          {!user &&
            (displayRemindPassword ? (
              <RemindPassword switchView={switchView} />
            ) : (
              <LoginForm switchView={switchView} />
            ))}
        </div>
      )}
    </div>
  );
};

export default LoginBody;
