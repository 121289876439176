import type {
  GetServerSideProps,
  InferGetServerSidePropsType,
  NextPage,
} from 'next';
import Login from 'Features/Login';
import { ThemeContextProvider } from 'common/context/Theme';
import * as cookie from 'cookie';
import Head from 'next/head';

export const CLIENTS: any = {
  indy: 'The Independent',
  standard: 'Evening Standard',
  jpi: 'National World',
  newschain: 'NewsChain',
  tindle: 'Tindle',
  reviewcenter: 'Review center'
};

const LoginPage: NextPage = ({
  theme,
  pub,
  publication,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  console.log({ pub, publication, state: process.env.NEXT_PUBLIC_ENV_STATE });
  return (
    <ThemeContextProvider app_theme={theme}>
      <Head>
        <title>{`FLOW - ${CLIENTS[pub]}`}</title>
        <meta
          name="description"
          content="The innovative web content solution trusted by over 200 publications Our realtime CMS makes creating content fast and provides journalists with an evolving tool suite using data-driven AI "
        />
        <meta name="robots" content="noindex"></meta>
        <link rel="icon" href="/icons/favicon-16x16.png" />
      </Head>
      <Login />
    </ThemeContextProvider>
  );
};
export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader(
    'Cache-Control',
    'public,  s-maxage=300, stale-while-revalidate=59',
  );
  context.res.setHeader('Content-Security-Policy', `frame-ancestors 'self';`);

  try {
    const cookies = context.req.headers.cookie
      ? cookie.parse(context.req.headers.cookie)
      : null;
    const theme = cookies && cookies.theme ? cookies.theme : null;

    return {
      props: {
        theme: theme || '#2196f3',
        pub: process.env.PUBLICATION,
        publication: process.env.DOMAIN,
      },
    };
  } catch (err) {
    console.log(err);
  }

  return { props: { data: 'err' } };
};
export default LoginPage;
