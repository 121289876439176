import axios from 'axios';
import React, {
  createContext,
  ReactNode,
  FC,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useRouter } from 'next/router';
import { cookies } from 'common/context/User/UserContext';
import { NotificationsContext } from 'common/context/Notifications';
import { notificationTypes } from 'common/context/Notifications/NotificationsContext';

type Props = {
  children: ReactNode;
};
const USER_NO_MFA = ['CMS Admin', 'CMS Editor', 'CMS Internal'];
interface Feature {
  id: number;
  value: string;
}

interface RoleData {
  name: string;
  id: number;
  features: Feature[];
}

interface Role {
  type: string;
  data: RoleData;
}

function extractData(arr: Role[]): string[] {
  return arr.reduce((result: string[], item) => {
    if (item.data && item.data.name) {
      result.push(item.data.name);
    }
    return result;
  }, []);
}

export interface loginTypes {
  state: any;
  handleChange: any;
  handleLoginSubmit: any;
  user: any;
  handleCheckCode: any;
  qr: any;
  handleCheckCodeFirst: any;
  error: any;
  checkCodeError: string;
  checkCodePending: boolean;
  loginSuccess: any;
  setLoginSuccess: any;
  loading: any;
  verifyingCode: any;
  fetchQr: any;
  handleChangeCodeMfa: any;
  handleChangeCode: any;
}
export const Context = createContext({} as any);

export const Provider: FC<Props> = ({ children }) => {
  const router = useRouter();
  const { addAlert } = useContext<notificationTypes>(NotificationsContext);

  const [state, setState] = useState<any>({
    username: '',
    password: '',
    code: '',
    mfaCode: '',
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [qr, setQr] = useState('');
  const [token, setToken] = useState('');
  const [header, setHeader] = useState('');
  const [secret, setSecret] = useState('');
  const [error, setError] = useState('');
  const [maxAge, setMaxAge] = useState(new Date());
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [checkCodeError, setCheckCodeError] = useState<string>('');
  const [checkCodePending, setCheckCodePending] = useState<boolean>(false);

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleChangeCode = (e: any) => {
    setState({ ...state, code: e });
  };
  const handleChangeCodeMfa = (e: any) => {
    setState({ ...state, mfaCode: e });
  };
  const [loginSuccess, setLoginSuccess] = useState(false);

  interface LoginResponse {
    current_user?: {
      name: string;
      email: string;
      image?: string;
      features: any;
      uid: string;
      development?: boolean;
    };
    csrf_token: string;
    sesion?: any;
    maxAge?: number;
  }

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { username, password } = state;
      const response = (await axios.post<LoginResponse>('/api/auth/login', {
        username,
        password,
      })) as {
        data: LoginResponse;
      };
      const userData = response.data.current_user as any;

      if (userData) {
        const { flowRoles } = userData;
        const roles = extractData(flowRoles);
        if (userData.development) {
          // Handle no MFA scenario
          router.push('/');
          setLoginSuccess(true);
          cookies.set(
            'flowUser',
            {
              name: userData?.name ?? undefined,
              email: userData?.email ?? undefined,
              image: userData?.image ?? undefined,
              features: userData?.features ?? undefined,
              uid: userData?.uid ?? undefined,
              roles: roles ?? undefined,
            },
            {
              path: '/',
              expires: new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
              ),
            }
          );
        } else {
          setUser(userData);
          setToken(response.data.csrf_token);
          setHeader(response.data.sesion);
          const currentTimeAsMs = Date.now();
          setMaxAge(new Date(currentTimeAsMs + (response.data.maxAge || 0)));
        }
      } else {
        console.log('Login failed: No user data received');
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'There was an error';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckCode = async (e: any) => {
    e.preventDefault();
    setCheckCodePending(true)
    setCheckCodeError('')

    try {
      setVerifyingCode(true);
      const { code } = state;
      const { secret } = user.mfa;
      const mfaCheck = await axios
        .post('/api/mfa/verify', { code, secret, user, header, token, maxAge })
        .catch(function (error) {
          setState({
            username: '',
            password: '',
            code: '',
          });
          setVerifyingCode(false);
          setCheckCodeError('There was an error with your code')
        });
      const { flowRoles } = user;
      const roles = extractData(flowRoles);
      mfaCheck &&
        user &&
        cookies.set(
          'flowUser',
          {
            name: user.name,
            email: user.email,
            image: user.image,
            features: user.features,
            uid: user.uid,
            roles,
          },
          {
            path: '/',
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1),
            ),
          },
        );

      router.push('/');
    } catch (err) {
      console.log(err);
      setCheckCodePending(false);
      setVerifyingCode(false);
      setCheckCodeError('There was an error with your code')
    }

    if (!user) {
      return;
    }
    if (!user.mfa) {
      return;
    }
  };

  const handleCheckCodeFirst = async (e: any) => {
    setCheckCodePending(true)
    setCheckCodeError('')

    try {
      e.preventDefault();
      const mfaCheck = await axios
        .post('/api/mfa/verify', {
          code: state.mfaCode,
          secret,
          user,
          header,
          token,
          maxAge,
        })
        .catch(function (error) {
          setCheckCodeError(error?.response?.data?.message)
          setState({
            username: '',
            password: '',
            code: '',
          });
        });

      const payload = {
        field_mfa_data: [
          {
            value: JSON.stringify({ secret: secret }),
          },
        ],
      };
      mfaCheck &&
        user &&
        (await axios.patch(
          `/api/user/set_mfa?id=${user.uid}&token=${token}&header=${header}`,
          payload,
          {
            headers: {
              'X-CSRF-Token': token,
              'Content-Type': 'application/json',
            },
          },
        ),
          setUser((prevState: any) => {
            return {
              ...prevState,
              mfa: secret,
            };
          }));
      mfaCheck &&
        user &&
        cookies.set(
          'flowUser',
          {
            name: user.name,
            image: user.image,
            features: user.features,
            uid: user.uid,
          },
          {
            path: '/',
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1),
            ),
            sameSite: true,
          },
        );
      mfaCheck && user && router.push('/');
    } catch (err) {
      console.log({ err });
    }

    setCheckCodePending(false)
  };
  const fetchQr = async () => {
    const res = await axios.get('/api/mfa/qr');
    setQr(res.data.qr);
    setSecret(res.data.secretCode);
  };

  const verifyUser = async () => {
    try {
      const { data } = await axios.get('/api/auth/verify');
      if (data) {
        router.push('/');
      }
    } catch (err) {
      console.log('err', err);
    }

  }

  useEffect(() => {
    verifyUser();
  }, []);

  const configContext = {
    state,
    handleChange,
    handleLoginSubmit,
    user,
    handleCheckCode,
    qr,
    handleCheckCodeFirst,
    error,
    loginSuccess,
    setLoginSuccess,
    loading,
    fetchQr,
    verifyingCode,
    handleChangeCode,
    handleChangeCodeMfa,
    checkCodeError,
    checkCodePending,
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
