import { LoginContextProvider } from './context';
import LoginBodyForm from './LoginBody/LoginBodyForm';
import { AuthSliderLayout } from 'common/Layouts/AuthLayout';

const Login = () => {
  return (
    <>
      <LoginContextProvider>
        <AuthSliderLayout>
          <LoginBodyForm />
        </AuthSliderLayout>
      </LoginContextProvider>
    </>
  );
};

export default Login;
