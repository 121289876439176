import Button from 'components/FlowUi/Button';
import Card from 'components/FlowUi/Card';
import { Input } from 'components/Mui';
import { LoginContext } from 'Features/Login/context';
import { loginTypes } from 'Features/Login/context/LoginContext';
import { useContext } from 'react';
import dynamic from 'next/dynamic';
import style from '../../Login.module.css';

import ReactCodeInput from 'react-code-input';
import { IoIosWarning } from 'react-icons/io';

const MfaCheck = () => {
  const {
    state,
    handleChange,
    handleChangeCode,
    handleCheckCode,
    verifyingCode,
    checkCodeError,
  } = useContext<loginTypes>(LoginContext);

  return (
    <form onSubmit={handleCheckCode} className={style.StepWrapper}>
      <div className={style.MfaCard}>
        <h1 className={style.Title_Sm}>Authenticate your account</h1>
        <p className={style.Subtitle}>
          Please confirm your account before we can log you in.
        </p>
        <ol className={style.MfaInstruction}>
          <li>
            Open the <strong>Google Authenticator</strong> app on your phone
          </li>
          <li>Enter the 6-digit verification code below:</li>
        </ol>
        <>
          {/*          <Input
            label="Enter code"
            value={state.code}
            handleChange={handleChange}
            name="code"
      />*/}
          <ReactCodeInput
            inputMode={'numeric'}
            value={state.code}
            onChange={handleChangeCode}
            fields={6}
            name="code"
            className={style.MfaCode}
            style={{
              display: 'flex',
            }}
            inputStyle={{
              margin: '0',
              borderRadius: '4px',
              borderStyle: 'solid',
              borderColor: checkCodeError
                ? 'var(--colour-alert-400)'
                : 'var(--colour-neutral-600)',
              borderWidth: '1px',
              background: 'var(--flow-2-neutral-100, #FCFEFF)',
              display: 'flex',
              width: '44px',
              height: '44px',
              padding: '12px',
              alignItems: 'center',
            }}
          />

          {checkCodeError && (
            <div className={style.errorMsg}>
              <IoIosWarning />
              <span>{checkCodeError}</span>
            </div>
          )}
        </>
      </div>
      <div className={style.Actions}>
        <Button disabled={verifyingCode} loading={verifyingCode} fullWidth>
          Verify account and login
        </Button>
      </div>
    </form>
  );
};

export default MfaCheck;
