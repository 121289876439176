import axios from 'axios';
import Button from 'components/FlowUi/Button';
import { Input } from 'components/Mui';
import { LoginContext } from 'Features/Login/context';
import { loginTypes } from 'Features/Login/context/LoginContext';
import { useContext, useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import style from '../../Login.module.css';
import { IoIosWarning } from 'react-icons/io';

const MfaSetUp = () => {
  const {
    state,
    handleChange,
    loading,
    error,
    qr,
    fetchQr,
    handleChangeCodeMfa,
    handleCheckCodeFirst,
    checkCodeError,
    checkCodePending,
  } = useContext<loginTypes>(LoginContext);
  useEffect(() => {
    fetchQr();
  }, []);

  return (
    <form onSubmit={handleCheckCodeFirst} className={style.StepWrapper}>
      <style jsx>{`
        .login_card_wrapper p {
          margin: 6px;
        }
        .app_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 8px;
          padding: 4px 0 0;
        }
        .app_wrapper img {
          display: block;
        }
        .qr_wrapper {
          display: flex;
          justify-content: center;
        }
        .store_logo {
          width: 120px;
        }
        @media (max-width: 1220px) {
          .store_logo {
            width: 120px;
          }
        }
        @media (max-width: 820px) {
          .store_logo {
            width: 100px;
          }
        }
      `}</style>
      <div className={style.MfaCard}>
        <h1 className={style.Title_Sm}>Two-factor authentication</h1>
        <p className={style.Subtitle}>
          You need to set up two-factor authentification. Each time you login
          will be asked to provide a verification code.
        </p>
        <ol className={style.MfaInstruction}>
          <li>
            Download the <strong>Google Authenticator</strong> app from your
            phone{`'`}s app store. <br />
            <div className="app_wrapper">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US"
              >
                <img
                  alt="Store logo"
                  className="store_logo"
                  src="https://iconape.com/wp-content/files/li/353328/png/get-it-on-google-play-badge-logo.png"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              >
                <img
                  className="store_logo"
                  src="https://iconape.com/wp-content/files/nd/353337/png/apple-store-logo.png"
                />
              </a>
            </div>
          </li>
          <li>
            Open the <strong>Google Authenticator</strong> app. Click the + icon
            and scan the QR code bellow.
          </li>
          <div className="qr_wrapper">
            <img style={{ justifySelf: 'center' }} src={qr} alt="qr_code" />
          </div>
          <li>Enter the verification code below:</li>
        </ol>

        <ReactCodeInput
          inputMode={'numeric'}
          value={state.mfaCode}
          onChange={handleChangeCodeMfa}
          fields={6}
          name="code"
          className={style.MfaCode}
          style={{
            display: 'flex',
          }}
          inputStyle={{
            margin: '0',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderColor: checkCodeError
              ? 'var(--colour-alert-400)'
              : 'var(--colour-neutral-600)',
            borderWidth: '1px',
            background: 'var(--flow-2-neutral-100, #FCFEFF)',
            display: 'flex',
            width: '44px',
            height: '44px',
            padding: '12px',
            alignItems: 'center',
          }}
        />

        {checkCodeError && (
          <div className={style.errorMsg}>
            <IoIosWarning />
            <span>{checkCodeError}</span>
          </div>
        )}
      </div>{' '}
      <div className={style.Actions}>
        <Button className={style.Button} fullWidth loading={checkCodePending}>
          Enable two-factor authentication
        </Button>
      </div>
    </form>
  );
};

export default MfaSetUp;
